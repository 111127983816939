import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceInfoService } from '../shared/services/device-info.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  latLon: any;
  ipAddress: any;
  constructor(
    private http: HttpClient,
    private deviceInfoService: DeviceInfoService
  ) {}

  //Login API calls
  getCurrentIp() {
    return this.http.get('api/ip/?format=json');
  }

  verifyLogin(requestData: any) {
    return this.http.post(`api/${requestData.url}`, requestData.data);
  }
  getUserData() {
    return JSON.parse(localStorage.getItem('userData') || '{}');
  }

  setUserData(data: any) {
    this.sendToSW(data);
    localStorage.setItem('userData', JSON.stringify(data));
  }
  sendToSW(data: any) {
    let payload: any = { type: 'userData' };
    Object.keys(data).forEach((key) => {
      if (['access', 'profile', 'subscriber'].includes(key)) {
        payload[key] = data[key];
      }
    });
    navigator?.serviceWorker?.controller?.postMessage(payload);
  }
  sendVCode(requestData: any) {
    return this.http.post('api/register_login/send_v_code/', requestData.data);
  }
  async checkDevice() {
    const body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(this.http.post('api/check_device/', body));
  }
  sendSignInLink(requestData: any) {
    return this.http.post(
      'api/register_login/send_signin_link/',
      requestData.data
    );
  }
  checkSignInLink(key: any) {
    return this.http
      .get('/api/register_login/check_link_validity/', {
        params: { b64_string: key },
      })
      .pipe(map((response) => response));
  }

  selectSubscriber(key: any, subscriberID: any, rememberLogin: number = 0) {
    return this.http
      .get('/api/register_login/select_subscriber/', {
        params: {
          token: key,
          subscriber_id: subscriberID,
          remember_login: rememberLogin,
        },
      })
      .pipe(map((response) => response));
  }
  checkRegistrationLink(key: any) {
    return this.http
      .get('/api/register_login/check_registration_link/', {
        params: { token: key },
      })
      .pipe(map((response) => response));
  }

  //Registration API calls
  initialRegister(requestData: any) {
    return this.http.post('api/register_login/', requestData);
  }

  checkActivationLink(key: any) {
    return this.http.get('api/register_login/check_activation_link/', {
      params: { token: key },
    });
  }

  sendVerificationCode(email: any, first_name: any, last_name: any) {
    const body = { email: email, first_name: first_name, last_name: last_name };
    return this.http.post('api/external_api/send_verification_code/', body);
  }

  fidoAuthenticate(body?: any, params?: any) {
    return this.http.post('api/register_login/fido_authenticate/', body, {
      params: params,
    });
  }
}
